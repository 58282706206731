import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { gettext } from '@eventbrite/i18n';
import { Icon } from '@eventbrite/eds-icon';
import { Button } from '@eventbrite/eds-button';
import { CrossChunky } from '@eventbrite/eds-iconography';
import { ActionClose } from '@eventbrite/eds-iconography';
import { IconButton } from '@eventbrite/eds-icon-button';
import { ICON_TYPE_PROP_TYPE } from '@eventbrite/eds-icon';
import { STYLE_INVERSE, STYLE_DARK } from '@eventbrite/eds-icon-button';
import {
    STYLE_ANCHOR as BTN_STYLE_ANCHOR,
    STYLE_INVERSE as BTN_STYLE_INVERSE,
} from '@eventbrite/eds-button';
import { getAdditionalProps } from '@eventbrite/eds-utils';
import { HAS_WINDOW } from '@eventbrite/feature-detection';

import * as constants from './constants';

import './notificationBar.scss';

const NotificationBarIcon = ({
    iconColor,
    iconType,
    shouldHideIconOnNarrowDevices,
    shouldShow,
}) => {
    let component = null;

    if (shouldShow && iconType) {
        component = (
            <div
                className={classNames('eds-notification-bar__icon', {
                    'hide-narrow-breakpoints': shouldHideIconOnNarrowDevices,
                })}
            >
                <Icon
                    type={iconType}
                    color={iconColor}
                    data-spec="notification-bar-icon"
                />
            </div>
        );
    }

    return component;
};

const NotificationBarClose = ({
    isAppRebrandActive,
    shouldShow,
    onClick,
    useInverseStyle,
    iconColor,
}) => {
    let component = null;
    const style = useInverseStyle ? STYLE_INVERSE : STYLE_DARK;
    const iconType = isAppRebrandActive ? <ActionClose /> : <CrossChunky />;

    if (shouldShow) {
        component = (
            <div className="eds-notification-bar__close eds-l-pad-right-2 eds-l-pad-vert-1">
                <IconButton
                    iconType={iconType}
                    data-spec="close-button"
                    title={gettext('Close')}
                    style={style}
                    onClick={onClick}
                    iconColor={iconColor}
                />
            </div>
        );
    }

    return component;
};

const NotificationBarCTA = ({ isOrbital, callAction, onClick }) => {
    let component = null;

    if (callAction && onClick) {
        const buttonStyle = isOrbital ? BTN_STYLE_INVERSE : BTN_STYLE_ANCHOR;

        component = (
            <div
                className="eds-notification-bar__cta"
                data-spec="notification-bar-cta"
            >
                <Button
                    style={buttonStyle}
                    onClick={onClick}
                    data-spec="notification-bar-cta-btn"
                >
                    {callAction}
                </Button>
            </div>
        );
    }

    return component;
};

const MARGIN_BOTTOM_PX = '16';

/**
 * Notification bars are used to communicate a message to the user. We have four different variations: neutral, success, error, and orbital.
 */
export default class NotificationBar extends React.PureComponent {
    static propTypes = {
        /**
         * Icon to show on the left of the notification bar
         */
        iconType: ICON_TYPE_PROP_TYPE,
        /**
         * Usually plain text or a node with notification text
         */
        children: PropTypes.node.isRequired,
        /**
         * Type of notification
         */
        type: constants.NOTIFICATION_TYPES_PROP_TYPE,
        /**
         * Show/hide close button
         */
        hasCloseButton: PropTypes.bool,
        /**
         * CTA for notification
         */
        callAction: PropTypes.node,
        // DEPRECATED
        callActionString: PropTypes.node,
        /**
         * CTA callback for notification
         */
        onCallAction: PropTypes.func,
        // DEPRECATED
        callActionClick: PropTypes.func,
        /**
         * Callback method when close button has been clicked
         */
        onClose: PropTypes.func,
        /**
         * Hide notification
         */
        isHidden: PropTypes.bool,
        /**
         * Native rebranding style on
         */
        isAppRebrandActive: PropTypes.bool,
        /**
         * Container class name
         */
        __containerClassName: PropTypes.string,
        /**
         * Should hide icon on narrow devices
         */
        shouldHideIconOnNarrowDevices: PropTypes.bool,
        /**
         * Should scroll to notification on render
         */
        shouldScrollTo: PropTypes.bool,
    };

    static defaultProps = {
        type: constants.TYPE_NEUTRAL,
        isHidden: false,
        hasCloseButton: false,
        shouldScrollTo: false,
        isAppRebrandActive: false,
    };

    constructor(props) {
        super(props);

        const { isHidden } = props;
        const wrapperMarginBottom = MARGIN_BOTTOM_PX;
        const isScrollAtTop = true;

        this.state = { isHidden, isScrollAtTop, wrapperMarginBottom };
    }

    componentDidMount() {
        const { _notificationElement } = this;
        const { shouldScrollTo } = this.props;
        const { isHidden } = this.state;

        if (!isHidden && _notificationElement && shouldScrollTo) {
            this._scrollIntoView(_notificationElement);
        }

        const edsModalContentChildrenId = 'edsModalContentChildren';
        const edsModalContentChildrenElement = document.getElementById(
            edsModalContentChildrenId,
        );
        if (edsModalContentChildrenElement) {
            edsModalContentChildrenElement.addEventListener('scroll', () => {
                this.setState({
                    wrapperMarginBottom: Math.max(
                        MARGIN_BOTTOM_PX -
                            edsModalContentChildrenElement.scrollTop,
                        0,
                    ),
                    isScrollAtTop:
                        edsModalContentChildrenElement.scrollTop === 0,
                });
            });
        }
    }

    UNSAFE_componentWillReceiveProps({ isHidden }) {
        this.setState({ isHidden });
    }

    componentDidUpdate() {
        const { _notificationElement } = this;
        const { shouldScrollTo } = this.props;
        const { isHidden } = this.state;

        if (!isHidden && _notificationElement && shouldScrollTo) {
            this._scrollIntoView(_notificationElement);
        }
    }

    _handleCloseClick() {
        this.setState({ isHidden: true });
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    _setNotificationRef(element) {
        this._notificationElement = element;
    }

    _scrollIntoView(element) {
        if (element && HAS_WINDOW) {
            window.scroll(0, element.offsetTop);
        }
    }

    render() {
        if (this.state.isHidden) {
            return null;
        }

        const {
            iconType,
            children,
            hasCloseButton,
            shouldHideIconOnNarrowDevices,
            type,
            __containerClassName,
            isAppRebrandActive,

            // `callActionString` was deprecated in favor of `callAction`
            // `callActionClick` was deprecated in favor of `onCallAction`
            callAction,
            callActionString,
            onCallAction,
            callActionClick,
        } = this.props;

        const extraAttrs = getAdditionalProps(this);
        const isOrbital = type === constants.TYPE_ORBITAL;
        const isDefault =
            type === constants.TYPE_NEUTRAL || type === constants.TYPE_WARNING;
        const isSuccess = type === constants.TYPE_SUCCESS;
        const className = classNames(
            {
                'eds-notification-bar--has-close': hasCloseButton,
            },
            'eds-notification-bar',
            `eds-notification-bar--${type}`,
            __containerClassName,
        );
        const storeRef = this._setNotificationRef.bind(this);
        const iconColor = isDefault
            ? constants.ICON_COLOR
            : constants.ICON_INVERSE_COLOR;
        const showIcon = !isOrbital;
        const showClose = hasCloseButton;

        const wrapperClassName = isAppRebrandActive
            ? {
                  className: classNames(
                      'eds-notification-bar--rebranding-active',
                      'eds-g-cell',
                      'eds-g-cell-mw-10-12',
                      'eds-g-offset-mw-1-12',
                      'eds-g-cell-mn-12-12',
                      'eds-g-offset-mn-0-12',
                      'eds-g-cell-sw-10-12',
                      'eds-g-offset-sw-1-12',
                      'eds-g-cell-sn-12-12',
                      'eds-g-cell-12-12',
                      'eds-l-sn-pad-hor-4',
                      'eds-l-pad-hor-6',
                  ),
              }
            : '';

        const marginTop = this.state.isScrollAtTop
            ? MARGIN_BOTTOM_PX
            : MARGIN_BOTTOM_PX - 1;

        const styleAttribute = isAppRebrandActive
            ? {
                  style: {
                      marginBottom: `${this.state.wrapperMarginBottom}px`,
                  },
              }
            : '';
        const notificationBarStyle = isAppRebrandActive
            ? {
                  style: {
                      marginTop: `${marginTop}px`,
                  },
              }
            : '';

        return (
            <div {...wrapperClassName} {...styleAttribute}>
                <div
                    {...extraAttrs}
                    className={className}
                    role="alert"
                    data-spec="notification-bar"
                    ref={storeRef}
                    {...notificationBarStyle}
                >
                    <NotificationBarIcon
                        iconColor={iconColor}
                        iconType={iconType}
                        shouldHideIconOnNarrowDevices={
                            shouldHideIconOnNarrowDevices
                        }
                        shouldShow={showIcon}
                    />
                    <div className="eds-notification-bar__content">
                        <div className="eds-notification-bar__content-child">
                            {children}
                        </div>
                        <NotificationBarCTA
                            isOrbital={isOrbital}
                            callAction={callAction || callActionString}
                            onClick={onCallAction || callActionClick}
                        />
                    </div>
                    <NotificationBarClose
                        isAppRebrandActive={isAppRebrandActive}
                        shouldShow={showClose}
                        onClick={this._handleCloseClick.bind(this)}
                        useInverseStyle={!isDefault && !isSuccess}
                        iconColor={iconColor}
                    />
                </div>
            </div>
        );
    }
}
