/* eslint-disable react/no-unused-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { gettext, translationPropType } from '@eventbrite/i18n';
import { HAS_WINDOW } from '@eventbrite/feature-detection';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import { Crosshair as CrosshairSvg } from '@eventbrite/eds-iconography';
import { AutocompleteField } from '@eventbrite/eds-autocomplete-field';
import { getPlaceFromGooglePlaceId as getPlaceFromGooglePlaceIdApi } from '../api';
import {
    parseLocationHistorySuggestions,
    getOnlineEventsOption,
} from '../utils';
import { setNavigatorLastLocation } from '@eventbrite/personalization';
import {
    SEARCH_API_KEY_CONFIG,
    useFeatureFlagState,
} from '@eventbrite/splitio-checker';

import './LocationAutocomplete.scss';
import { loadGoogleMaps } from '../utils/load';
import { track } from '@eventbrite/datalayer-library';

const LOCATION_DEBOUNCE_INTERVAL = 250;
const BBOX_SEARCH_DISPLAY_STRING = gettext('map area')?.toString() || '';
const HEAP_MISSING_WOF_PLACE = 'All - Search - Location - MissingWOFPlace';
// When Google Places Autocomplete returns a Place Object, we attempt to match it
// to our internal Who's On First (WOF) database, because the app depends on WOF Place Ids
// and WOF location slugs. In some cases our WOF db does not have a match, and we
// want to track how often it happens.
// This is done in the library instead of the apps, because we want to track this
// for all apps at once.
// This new event is for tracking missing who's on first place when Google returns a place object but our WOF database doesn't have a corresponding placeID
const trackMissingWOFPlace = (locationName) => {
    if (window.ga) {
        window.ga('send', {
            hitType: 'event',
            eventCategory: 'search',
            eventAction: 'MissingWOFPlace',
            eventLabel: locationName,
            // nonInteraction is so we don't pollute the analytics
            nonInteraction: true,
        });
    }

    track({
        eventName: HEAP_MISSING_WOF_PLACE,
        page: { locationName: locationName },
    });
};

// Takes a clicked-on location from the Autocomplete suggestions prop,
// and calls our own API for our internal location properties
const getPlaceObj = (suggestedLocation, enableNeighborhoodLocations) => {
    const googlePlaceId = suggestedLocation.value;
    const excludeNeighborhoods = enableNeighborhoodLocations ? undefined : true;

    return new Promise((res, rej) => {
        getPlaceFromGooglePlaceIdApi(
            googlePlaceId,
            excludeNeighborhoods,
            enableNeighborhoodLocations,
        )
            .then((response) => {
                const { place } = response;

                if (place) {
                    res({
                        currentPlace: suggestedLocation.currentPlace,
                        currentPlaceParent:
                            place?.localizations?.default?.[
                                place.derived_ancestor_type
                            ]?.suggested_abbreviation ||
                            suggestedLocation.currentPlaceParent,
                        country:
                            place?.localizations?.default?.country?.full_name,
                        latitude: place.latitude,
                        longitude: place.longitude,
                        slug: place.location_slug,
                        placeType: place.place_type,
                        placeId: place.id,
                        isOnline: false,
                    });
                } else {
                    // if the API can't find a corresponding WOF place ID for the google place ID
                    // it returns a lat/lng and viewport
                    const {
                        latitude,
                        longitude,
                        viewport_northeast,
                        viewport_southwest,
                    } = response;

                    // only 'sw' and 'ne' are necesary but at least one line of code uses 'se'
                    // so we include 'nw' and 'se' just to be safe.
                    const bbox = {
                        nw: {
                            lng: viewport_southwest.longitude,
                            lat: viewport_northeast.latitude,
                        },
                        sw: {
                            lng: viewport_southwest.longitude,
                            lat: viewport_southwest.latitude,
                        },
                        ne: {
                            lng: viewport_northeast.longitude,
                            lat: viewport_northeast.latitude,
                        },
                        se: {
                            lng: viewport_northeast.longitude,
                            lat: viewport_southwest.latitude,
                        },
                    };

                    res({
                        currentPlace: suggestedLocation.currentPlace,
                        currentPlaceParent:
                            suggestedLocation.currentPlaceParent,
                        country: suggestedLocation.currentPlaceParent
                            ? suggestedLocation.currentPlaceParent
                                  .match(/[^,]*$/)[0]
                                  .trim()
                            : undefined,
                        latitude,
                        longitude,
                        isOnline: false,
                        bbox,
                    });

                    trackMissingWOFPlace(
                        `${suggestedLocation.currentPlace}, ${suggestedLocation.currentPlaceParent}`,
                    );
                }
            })
            .catch((error) => {
                rej(error);
            });
    });
};

const LocationAutocompleteGoogleSplitWrapper = (props) => {
    const enableNeighborhoodLocations = useFeatureFlagState(
        'enable_neighborhood_locations',
        SEARCH_API_KEY_CONFIG,
        undefined,
        'eb-ui',
    );
    return (
        <LocationAutocompleteGoogle
            {...props}
            enableNeighborhoodLocations={enableNeighborhoodLocations}
        />
    );
};

export class LocationAutocompleteGoogle extends PureComponent {
    static propTypes = {
        clearError: PropTypes.func,
        currentPlace: PropTypes.string,
        placeId: PropTypes.string,
        placeholder: translationPropType,
        onComponentDidMount: PropTypes.func,
        onComponentWillUnmount: PropTypes.func,
        onGoogleMapsLoaded: PropTypes.func,
        onGoogleMapsLoadFailed: PropTypes.func,
        handleUseCurrentLocation: PropTypes.func,
        handleLocationSuggestionChange: PropTypes.func,
        // eslint-disable-next-line react/forbid-prop-types
        locationSuggestions: PropTypes.object,
        /**
         * Called immediately upon the select
         * occurring with no data passed back.
         */
        onLocationSelect: PropTypes.func,
        /**
         * Callback which includes data on the
         * selected location.
         */
        handleLocationSelect: PropTypes.func,
        handleSomethingWentWrongError: PropTypes.func,
        handleZeroResultsError: PropTypes.func,
        isBBoxSearch: PropTypes.bool,
        isUseCurrentLocationEnabled: PropTypes.bool,
        isOnline: PropTypes.bool,
        isWaitingForLocation: PropTypes.bool,
        isUsingCurrentLocation: PropTypes.bool,
        disabled: PropTypes.bool,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        autocompleteProps: PropTypes.shape({
            borderType: PropTypes.string,
            id: PropTypes.string,
            name: PropTypes.string,
            hideOutline: PropTypes.bool,
            isLimitedSearch: PropTypes.bool,
            preventInputValuePrefill: PropTypes.bool,
        }),
        prefix: PropTypes.node,
        enableNeighborhoodLocations: PropTypes.bool,
    };

    static defaultProps = {
        autocompleteProps: {
            borderType: 'none',
            id: 'locationPicker',
            name: 'locationPicker',
            hideOutline: true,
            isLimitedSearch: true,
            preventInputValuePrefill: true,
        },
    };

    constructor(props) {
        super(props);

        const {
            currentPlace,
            isBBoxSearch,
            locationSuggestions: { suggestions },
        } = props;

        let locationQuery = currentPlace;

        if (isBBoxSearch) {
            locationQuery = BBOX_SEARCH_DISPLAY_STRING;
        }

        this.state = {
            locationQuery,
            suggestions,
            isTyping: false,
            selectedLocation: currentPlace,
            googleSuggestions: [],
            loadApiAttempted: false,
        };

        this.historySuggestions = parseLocationHistorySuggestions();
    }

    componentDidMount() {
        const { onComponentDidMount } = this.props;

        if (onComponentDidMount) {
            onComponentDidMount();
        }

        this._isItemSelected = false;
        this._suggestionsPending = false;
        this._mounted = true;
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps({ currentPlace, isBBoxSearch }) {
        let newLocationQuery = currentPlace;

        if (isBBoxSearch) {
            newLocationQuery = BBOX_SEARCH_DISPLAY_STRING;
        }

        this.setState(
            ({
                isTyping,
                locationQuery: currentLocationQuery,
                selectedLocation: currentSelectedLocation,
            }) => {
                let locationQuery = currentLocationQuery;

                let selectedLocation = currentSelectedLocation;

                if (!isTyping) {
                    locationQuery = newLocationQuery;
                    selectedLocation = newLocationQuery;
                }

                return { locationQuery, selectedLocation };
            },
        );
    }

    componentWillUnmount() {
        const { onComponentWillUnmount } = this.props;

        this._mounted = false;

        if (onComponentWillUnmount) {
            onComponentWillUnmount();
        }
    }

    _handleLocationAutocompleteSelect = (selectedValue) => {
        const {
            handleLocationSelect,
            handleUseCurrentLocation,
            handleSomethingWentWrongError,
            isUseCurrentLocationEnabled,
            onLocationSelect,
            clearError,
            enableNeighborhoodLocations,
        } = this.props;

        // When the user has selected a new location, we want to clear
        // any previous errors from the view
        onLocationSelect?.();
        clearError?.();

        const { googleSuggestions } = this.state;
        const suggestedLocation = find(
            googleSuggestions,
            ({ value }) => value === selectedValue,
        );

        this._isItemSelected = true;

        if (selectedValue.startsWith('recent-')) {
            // Selected a location from history
            const historySuggestion = this.historySuggestions.find(
                ({ value }) => value === selectedValue,
            );

            this.setState(
                {
                    locationQuery: historySuggestion.currentPlace,
                    selectedLocation: historySuggestion.currentPlace,
                    isTyping: false,
                },
                () => {
                    handleLocationSelect(historySuggestion);

                    this._isItemSelected = false;
                },
            );
        } else if (suggestedLocation && suggestedLocation.value) {
            getPlaceObj(suggestedLocation, enableNeighborhoodLocations)
                .then((placeObj) => {
                    if (this.props.storeLocation) {
                        setNavigatorLastLocation({
                            ...placeObj,
                            value: `recent-${placeObj.placeId}`,
                            content: placeObj.currentPlace,
                            secondaryContent: placeObj.currentPlaceParent,
                            isHistorySuggestion: true,
                            country: placeObj.country,
                        });
                    }
                    handleLocationSelect(placeObj);

                    this._mounted &&
                        this.setState(
                            {
                                locationQuery: suggestedLocation.currentPlace,
                                selectedLocation:
                                    suggestedLocation.currentPlace,
                                isTyping: false,
                            },
                            () => {
                                this._isItemSelected = false;
                            },
                        );
                })
                .catch((error) => {
                    handleSomethingWentWrongError?.();
                    throw new Error(
                        `Received error when calling getPlaceFromGooglePlaceIdApi: ${error}`,
                    );
                });
        } else if (selectedValue === 'online') {
            this.setState({ isTyping: false }, () => {
                handleLocationSelect({
                    ...getOnlineEventsOption(),
                    placeId: null,
                });
                this._isItemSelected = false;
            });
        } else if (
            isUseCurrentLocationEnabled &&
            selectedValue === 'use-current'
        ) {
            this.setState(
                {
                    locationQuery: '',
                    selectedLocation: '',
                    isTyping: false,
                },
                () => {
                    handleUseCurrentLocation();
                    this._isItemSelected = false;
                },
            );
        } else if (!this._suggestionsPending) {
            // If we get here and there are no suggestions pending, something went wrong
            handleSomethingWentWrongError?.();
        }
    };

    _handleLocationAutocompleteChange = (locationQuery) => {
        this.setState({ locationQuery });

        // In the case that the query is empty,
        // it doesn't matter that locations are
        // pending. So we should ensure full functionality
        if (locationQuery) {
            this._suggestionsPending = true;
        }

        this._requestLocationSuggestions(locationQuery);
    };

    _handleEnterKey = (query) => {
        this._requestLocationSuggestions.cancel();
        if (this._suggestionsPending) {
            this._handleGetSuggestionsFromGoogle(query, true);
        }
    };

    _handleLocationAutocompleteBlur = () => {
        const { selectedLocation } = this.state;

        this.setState({
            locationQuery: selectedLocation,
            isTyping: false,
        });

        if (this.props.onBlur) {
            this.props.onBlur();
        }
    };

    _handleLocationAutocompleteFocus = () => {
        if (!this.state.loadApiAttempted) {
            loadGoogleMaps({
                onSuccess: this.props.onGoogleMapsLoaded,
                onFailure: this.props.onGoogleMapsLoadFailed,
            });
            this.setState({ loadApiAttempted: true });
        }
        /* The autocomplete will refocus on the input, triggering the handlefocus
        callback when an item is selected. We don't want to clear the input _after_
        a user selected an item but only when initially clicking. At the moment,
        selecting an item triggers a redirect so it will be reset each time. */
        if (!this._isItemSelected) {
            this.historySuggestions = parseLocationHistorySuggestions();

            this.setState({
                locationQuery: '',
                isTyping: true,
                googleSuggestions: [],
            });

            this._requestLocationSuggestions('');
        }

        if (this.props.onFocus) {
            this.props.onFocus();
        }
    };

    /**
     * Make a request to google for location suggestions and process the results.
     *
     * @param {string} query The location query that should be completed e.g. "Los Ang"
     * @param {bool} selectFirstResult Select the first autocomplete result returned from
     *     google. Useful if a user presses the enter key quickly after typing.
     */
    _handleGetSuggestionsFromGoogle = (query, selectFirstResult) => {
        const {
            latitude,
            longitude,
            handleZeroResultsError,
            handleSomethingWentWrongError,
            clearError,
            enableNeighborhoodLocations,
        } = this.props;

        clearError?.();
        const isMapsOnWindow = HAS_WINDOW && !!window?.google?.maps;

        if (!query || !isMapsOnWindow) {
            return this.setState({
                locationQuery: '',
                googleSuggestions: [],
            });
        }

        let request = {
            input: query,
            types: enableNeighborhoodLocations
                ? [
                      'neighborhood',
                      'locality',
                      'sublocality',
                      'country',
                      'administrative_area_level_1',
                  ]
                : ['(regions)'],
        };

        if (latitude && longitude) {
            request = {
                ...request,
                location: new window.google.maps.LatLng(latitude, longitude),
                // radius is in meters
                radius: 200000,
            };
        }

        const autocomplete =
            new window.google.maps.places.AutocompleteService();

        return autocomplete.getPlacePredictions(request, (places, status) => {
            this._suggestionsPending = false;

            // In the case of ZERO_RESULTS, the suggestions will be empty,
            // exit early so that we will keep the most recent set of results
            if (status === 'ZERO_RESULTS' && !places) {
                handleZeroResultsError?.();
                this.setState({
                    locationQuery: '',
                    googleSuggestions: [],
                });
                return;
            }

            if (status !== 'OK') {
                handleSomethingWentWrongError?.();
                this.setState({
                    locationQuery: '',
                    googleSuggestions: [],
                });
                throw new Error(`Received error from Google places: ${status}`);
            }

            const placeSuggestions = places.map((place) => ({
                content: place.structured_formatting.main_text,
                secondaryContent: place.structured_formatting.secondary_text,
                value: place.place_id,
                currentPlace: place.structured_formatting.main_text,
                currentPlaceParent: place.structured_formatting.secondary_text,
                country: place.structured_formatting.secondary_text
                    ? place.structured_formatting.secondary_text
                          .match(/[^,]*$/)[0]
                          .trim()
                    : undefined,
            }));

            this.setState({ googleSuggestions: placeSuggestions });

            if (selectFirstResult) {
                this._handleLocationAutocompleteSelect(
                    placeSuggestions[0].value,
                );
            }
        });
    };

    _requestLocationSuggestions = debounce(
        this._handleGetSuggestionsFromGoogle,
        LOCATION_DEBOUNCE_INTERVAL,
    );

    render() {
        const {
            isWaitingForLocation,
            autocompleteProps,
            placeholder,
            isUseCurrentLocationEnabled,
            disabled,
            prefix,
            isOnline,
            isUsingCurrentLocation,
            showItemDivider,
            suffix,
        } = this.props;

        const { locationQuery, selectedLocation, googleSuggestions } =
            this.state;

        // Note: Don't store in variable or else it can't be translated
        let value =
            locationQuery === gettext('Your Location')
                ? gettext('Your Location')
                : locationQuery;

        let _placeholder = placeholder || selectedLocation;

        if (_placeholder === gettext('Your Location')) {
            _placeholder = gettext('Your Location');
        }

        let suggestions = [];

        if (!isEmpty(googleSuggestions)) {
            suggestions = [...googleSuggestions];
        } else {
            if (isUseCurrentLocationEnabled && !isUsingCurrentLocation) {
                suggestions = [
                    {
                        id: 'use-current',
                        content: gettext('Use my current location'),
                        value: 'use-current',
                        iconType: <CrosshairSvg />,
                        showDivider: showItemDivider,
                    },
                ];
            }

            if (!isOnline) {
                suggestions = [...suggestions, getOnlineEventsOption()];
            }

            suggestions = [...suggestions, ...this.historySuggestions];
        }

        if (isWaitingForLocation) {
            value = '';
            _placeholder = gettext('Finding location...');
        }

        return (
            <div
                className="location-autocomplete"
                data-spec="location-autocomplete"
            >
                <AutocompleteField
                    suggestions={suggestions}
                    value={value}
                    onBlur={this._handleLocationAutocompleteBlur}
                    onChange={this._handleLocationAutocompleteChange}
                    onFocus={this._handleLocationAutocompleteFocus}
                    onSelect={this._handleLocationAutocompleteSelect}
                    onEnterKey={this._handleEnterKey}
                    shouldFocusInputOnSelect={false}
                    isV2={true}
                    placeholder={_placeholder}
                    disabled={disabled || isWaitingForLocation}
                    prefix={prefix}
                    suffix={suffix}
                    {...autocompleteProps}
                    isAsideClickable={true}
                />
            </div>
        );
    }
}

export default LocationAutocompleteGoogleSplitWrapper;
