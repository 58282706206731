import * as React from 'react';

const ActionCloseSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        {...props}
    >
        <path
            d="M8.82123 9.99981L2.5 16.321L3.67851 17.4996L9.99974 11.1783L16.321 17.4996L17.4996 16.3211L11.1783 9.99981L17.4996 3.67851L16.321 2.5L9.99974 8.8213L3.67851 2.50007L2.5 3.67858L8.82123 9.99981Z"
            fill="white"
        />
    </svg>
);

ActionCloseSvg.displayName = 'ActionCloseSvg';
export default ActionCloseSvg;
